import Vue from 'vue'
import Vuex from 'vuex'
import system_settings from '@/modules/store/system-settings/state.js'
import email_form from '@/modules/store/email_form/state.js'
import subscribers from '@/modules/store/subscribers/state';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        system_settings,
        email_form,
        subscribers
    },
})
