var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',[_c('a-col',{staticStyle:{"padding":"10px 0 0 10px"},attrs:{"xs":12}},[_c('h2',[_vm._v("List Subscribers")])]),_c('a-col',{staticStyle:{"text-align":"right","padding":"10px 0"},attrs:{"xs":12}},[_c('a-input-search',{staticStyle:{"max-width":"500px"},attrs:{"placeholder":"search by name or email","allow-clear":"","loading":_vm.searching},on:{"search":_vm.onSearch},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)],1),_c('a-row',[_c('a-col',[_c('a-table',{attrs:{"data-source":_vm.subscriberList,"columns":_vm.visibleColumns,"row-key":"id","size":"small","pagination":{
          pageSize: _vm.pageSize,
          total: _vm.totalRows,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
          showTotal: (total) => `Found ${total} Subscribers`
        },"loading":_vm.loading,"custom-row":((record) => {
          return {
            on: {
              click: (e) => _vm.onRowClick(e, record)
            }
          }
        })},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"is_subscribe",fn:function(text, record){return [(+record.is_subscribe === 1)?_c('a-icon',{style:({ fontSize: '16px', color: '#52c41a' }),attrs:{"type":"check-circle","theme":"outlined"}}):(_vm.isAdmin)?_c('a-checkbox',{attrs:{"default-checked":+record.is_subscribe === 1},on:{"change":function($event){return _vm.handleSubscribe(record)}}}):_vm._e()]}},{key:"is_unsubscribe",fn:function(text, record){return [(+record.is_unsubscribe === 1)?_c('a-icon',{style:({ fontSize: '16px', color: '#E36F66' }),attrs:{"type":"check-circle","theme":"outlined"}}):(_vm.isAdmin)?_c('a-checkbox',{attrs:{"default-checked":+record.is_unsubscribe === 1},on:{"change":function($event){return _vm.handleUnsubscribe(record)}}}):_vm._e()]}},{key:"created_at",fn:function(text, record){return [_vm._v(" "+_vm._s(_vm._f("moment")(record.created_at,"L"))+" ")]}},{key:"action",fn:function(text, record){return [(_vm.isAdmin)?_c('a-space',{attrs:{"size":3}},[_c('a-button',{attrs:{"type":"link","size":"small","icon":"delete"},on:{"click":function($event){return _vm.handleDelete(record)}}}),_c('a-button',{attrs:{"type":"link","size":"small","icon":"eye"},on:{"click":function($event){return _vm.handleOpenUpdate(record)}}})],1):_vm._e()]}}])}),(_vm.isAdmin)?_c('SubscriberModal',{model:{value:(_vm.showSubscriberModal),callback:function ($$v) {_vm.showSubscriberModal=$$v},expression:"showSubscriberModal"}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }