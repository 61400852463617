export default {
    //getters
    GET_FORM_FIELD_VALUE: 'email_form/GET_FORM_FIELD_VALUE',
    GET_EMAIL_RECEIVERS: 'email_form/GET_EMAIL_RECEIVERS',

    //mutation
    SET_FORM_FIELD_VALUE: 'email_form/SET_FORM_FIELD_VALUE',
    SET_RECEIVER_DATA: 'email_form/SET_RECEIVER_DATA',

    //actions
    CLEAR_FORM_FIELDS: 'email_form/CLEAR_FORM_FIELDS',
    DELETE_RECEIVER: 'email_form/DELETE_RECEIVER',
    SORT_RECEIVERS: 'email_form/SORT_RECEIVERS',
    ADD_NEW_RECEIVER: 'email_form/ADD_NEW_RECEIVER',
}