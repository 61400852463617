export default {
    namespaced: true,
    state: {
        sidebar_visibility: false,
        form_modal_placement: 'right',
        user_details: {}
    },
    getters: {
        GET_SIDEBAR_VISIBILITY: state => state.sidebar_visibility,
        GET_FORM_MODAL_PLACEMENT: state => state.form_modal_placement,
        GET_USER_DETAILS: state => state.user_details,
    },
    actions: {
        GENERATE_DUMMY_USER({commit, state}){
            state.user_details = {
                user: {
                    displayName: 'Hello World',
                    emails: [
                        'tester@gmail.com'
                    ],
                    photos: [
                        'https://i.sstatic.net/34AD2.jpg'
                    ]
                }
            }
        }
    },
    mutations: {
        SET_SIDEBAR_VISIBILITY(state, status) {
            state.sidebar_visibility = status;
        },
        SET_USER_DETAILS(state, data) {
            state.user_details = data;
        },
    }
}