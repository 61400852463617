<template>
  <div id="app" class="no-select">
    <div v-if="is_loading_session == false">
      <a-layout>
        <a-layout-header v-if="!['/login','/subscriber/unsubscribe','/subscriber/subscribe'].includes($router.currentRoute.path)">
          <div class="d-flex justify-content-space-between align-items-center h-100 p-15">
            <a-icon type="menu" @click="showDrawer"/>
            <a-avatar :src="getUserDetails.user.photos[0].value" @click="showUserSettingsDrawer" />
          </div>
        </a-layout-header>
        <a-layout-content>
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
      <a-drawer
        :placement="placement"
        :closable="false"
        :visible="getSidebarVisibility"
        @close="onClose"
        id="drawer-sidebar"
      >
        <div class="d-flex justify-content-center align-items-center m-2 app-logo-container">
          <img class="app-icon" src="https://eshyft.com/wp-content/uploads/2019/07/cropped-eshyftlogoweb-300x70.png" />
        </div>
        <SidebarItem v-for="(menu, index) in getMenus()"
          :path="menu.path"
          :name="menu.name"
          :icon="menu.icon"
          :key="index"
          :is_show_on_sidebar="'is_show_on_sidebar' in menu && menu.is_show_on_sidebar == false? false:true "
          :current_route_path="current_path" />
      </a-drawer>
      <a-drawer
        v-if="!['/login','/subscriber/unsubscribe','/subscriber/subscribe'].includes($router.currentRoute.path)"
        placement="bottom"
        :closable="true"
        :visible="show_user_settings_drawer"
        @close="closeUserSettingsDrawer"
      >
        <span slot="title"><a-icon type="setting" /> {{ `${getUserDetails.user.displayName} (${getUserDetails.user.emails[0].value})` }}</span>
          <a-button type="link" @click="logout">
            <a-icon type="logout" /> Logout
          </a-button>
      </a-drawer>
    </div>
    <a-skeleton v-else />
  </div>
</template>

<script>
import SidebarItem from '@/components/side-bar-item/SidebarItem.vue'
import menus from '@/constants/menus.js'
import system_settings_types from '@/modules/store/system-settings/types.js'
import { mapGetters, mapMutations } from "vuex";
import axios from 'axios'

export default {
  name: 'App',
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'E-Shyft',
      // all titles will be injected into this template
      titleTemplate: '%s | Emailer'
  },
  components: {
    SidebarItem
  },
  data() {
    return {
      placement: 'left',
      is_loading_session: true,
      current_path: '/',
      show_user_settings_drawer: false
    };
  },
  watch: {
      $route (to, from){
        this.current_path = this.$router.currentRoute.path
      }
  },
  computed: {
    ...mapGetters({
      getSidebarVisibility: system_settings_types.GET_SIDEBAR_VISIBILITY,
      getUserDetails: system_settings_types.GET_USER_DETAILS
    }),
    sidebarVisibility: {
      get() {
        return this.getSidebarVisibility
      },
      set(newValue) {
        this.setSidebarVisiblity(newValue);
      }
    },
  },
  methods: {
    ...mapMutations({
      setSidebarVisiblity: system_settings_types.SET_SIDEBAR_VISIBILITY,
      setUserDetails: system_settings_types.SET_USER_DETAILS,
    }),
    logout(){
      window.open(`${process.env.VUE_APP_ROOT_API}auth/logout`, "_self");
    },
    showUserSettingsDrawer(){
      this.show_user_settings_drawer = true
    },
    closeUserSettingsDrawer(){
      this.show_user_settings_drawer = false
    },
    showDrawer() {
      this.setSidebarVisiblity(true);
    },
    onClose() {
      this.setSidebarVisiblity(false);
    },
    onChange(e) {
      this.placement = e.target.value;
    },
    getMenus(){
      return menus;
    },
    onSessionFetchSuccess(res){
      this.setUserDetails(res.data)
    },
    generateDummySession(){
      this.$store.dispatch(system_settings_types.GENERATE_DUMMY_USER)
      alert("caller is " + new Error('I was called').stack);
    }
  },
  async mounted(){
    this.is_loading_session = true

    // if env is `dev` generate a dummy session
    const environment = (`${process.env.VUE_APP_ENVIRONMENT}`).toLowerCase()
    if(environment == 'dev'){
      this.generateDummySession()
      this.is_loading_session = false
      return
    }

    // if page is not on login, check the session
    if( !['/login','/subscriber/unsubscribe','/subscriber/subscribe'].includes(this.$router.currentRoute.path) ) {
      await axios({
          method: "get",
          url: `${process.env.VUE_APP_ROOT_API}auth/login/session`,
          withCredentials: true
        })
        .then(this.onSessionFetchSuccess)
        .catch(err => window.location.replace("/login"));
    }
    this.is_loading_session = false
    this.current_path = this.$router.currentRoute.path
  }
}
</script>

<style>
  @import './assets/css/_drawer-sidebar.css';
  @import './assets/css/_global.css';

  .app-icon {
    width: 150px;
  }

  .app-logo-container {
    height: 80px;
  }
</style>
